import { Box } from '@medsimples/design-system/src/chakra';
import { Button } from '@medsimples/design-system/src/components';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { tokens } from '../../styles/tokens';

interface Props {
  router: AppRouterInstance;
}

interface OptionalProps {
  color?: string;
  marginTop?: number;
  marginBottom?: number;
  action?: () => void;
  children?: string;
}

const defaultProps: Required<OptionalProps> = {
  color: tokens.button.terciary.scheme,
  action: null,
  marginTop: 0,
  marginBottom: 5,
  children: 'Voltar',
};

export default function BackButton(props: Props & OptionalProps) {
  const { router, marginTop, marginBottom, action, children } = {
    ...defaultProps,
    ...props,
  };

  const onClick = () => {
    if (action) {
      return action();
    }

    router.back();
    router.refresh();
  };

  return (
    <Box w='100%' marginTop={marginTop} marginBottom={marginBottom}>
      <Button
        onClick={onClick}
        backgroundColor={tokens.button.terciary.scheme}
        _hover={{ backgroundColor: tokens.button.terciary.colors.hover }}
      >
        {children}
      </Button>
    </Box>
  );
}
