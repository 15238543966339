import { SigninType } from '@medsimples/doctor-onboarding-openapi-v2';
import { OnboardingRoutes } from './routing';

export const STEPPER = {
  [OnboardingRoutes.ONBOARDING_DOCTOR_BASIC_INFO.path]: 0,
  [OnboardingRoutes.ONBOARDING_DOCTOR_CONFIRM_BASIC_INFO.path]: 0,
  [OnboardingRoutes.ONBOARDING_PERSONAL_ADDRESS.path]: 0,
  [OnboardingRoutes.ONBOARDING_FACIAL_RECOGNITION.path]: 1,
  [OnboardingRoutes.ONBOARDING_PROFESSIONAL_DOCUMENTS.path]: 2,
  [OnboardingRoutes.ONBOARDING_COMPANY_SEARCH_INFO.path]: 3,
  [OnboardingRoutes.ONBOARDING_COMPANY_CONFIRM_INFO.path]: 3,
  [OnboardingRoutes.ONBOARDING_COMPANY_CONFIRM_INFO_ADDRESS.path]: 3,
  [OnboardingRoutes.ONBOARDING_COMPANY_CONFIRM_INFO_STRUCTURE.path]: 3,
  [OnboardingRoutes.ONBOARDING_COMPANY_DOCUMENTS.path]: 3,
  [OnboardingRoutes.ONBOARDING_COMPANY_BANK_INFO.path]: 3,
  [OnboardingRoutes.ONBOARDING_COMPLIANCE.path]: 3,
  [OnboardingRoutes.ONBOARDING_USE_TERMS.path]: 3,
};

export const STATES = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const SigninMethodLabel = {
  email: 'email',
  phone: 'telefone',
} as const;

export const SiginMethodType = {
  email: SigninType.EMAIL_OTP,
  phone: SigninType.SMS_OTP,
} as const;

export const ValidFileTypes = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/webp',
  'image/heic',
  'image/x-ms-bmp',
  'application/pdf',
];

export const ValidFileTypesStr = ValidFileTypes.join(',');
