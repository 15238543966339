import schemes from './colorSchemes';

export const tokens = {
  button: {
    primary: {
      scheme: 'blue',
    },
    secondary: {
      scheme: 'blue.darker',
    },
    terciary: {
      scheme: 'gray',
      colors: {
        hover: 'gray.700',
      },
    },
  },
  // needs to reference the color string because sometimes is used outside a chakra component
  text: {
    primary: {
      color: schemes.medsimples.colors.blue[500],
    },
    secondary: {
      color: schemes.medsimples.colors['blue.darker'][500],
    },
    highlight: {
      color: schemes.medsimples.colors.cyan[500],
    },
    gray: {
      color: schemes.medsimples.colors.gray[500],
    },
    grayLight: {
      color: schemes.medsimples.colors.gray[100],
    },
    grayLighter: {
      color: schemes.medsimples.colors.gray[50],
    },
    yellow: {
      color: schemes.medsimples.colors.yellow[300],
    },
    green: {
      color: schemes.medsimples.colors.green[600],
    },
    orange: {
      color: schemes.medsimples.colors.orange[400],
    },
    red: {
      color: schemes.medsimples.colors.red[500],
    },
    white: {
      color: '#FFFFFF',
    },
  },
};
